import { makeStyles } from '@mui/styles';

export const globalBGColor = 'rgba(240,240,240,1)';

export const globalBGColorAlt = 'white';

export const textVariant = 'h5';

export const textStyles = makeStyles(() => ({
  textStyle: {
    color: 'black',
    fontFamily: 'monospace'
    // fontWeight: 'lighter'
  },
  altTextStyle: {
    color: 'white',
    fontFamily: 'monospace'
  },
  button: {
    border: '1px solid rgba(0,0,0, 0.5)',
    borderRadius: '20px'
  },
  centeredText: {
    textAlign: 'center'
  }
}));
