import { Grid } from '@mui/material';
// eslint-disable-next-line
import React, { useEffect, useCallback } from 'react';
import MyrtechAboutUs from './MyrtechAboutUs/MyrtechAboutUs';
import MyrtechGetInTouch from './MyrtechGetInTouch/MyrtechGetInTouch';
import MyrtechIndustries from './MyrtechIndustries/MyrtechIndustries';
import MyrtechIndusServ from './MyrtechIndusServ/MyrtechIndusServ';
import MyrtechLanding from './MyrtechLanding/MyrtechLanding';
import MyrtechNavBar from './MyrtechNavBar/MyrtechNavBar';

const MyrtechMain: React.FC = () => {
  let didScroll = false;
  setInterval(function () {
    if (didScroll) {
      didScroll = false;
    }
    // console.log(didScroll);
  }, 250);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{ width: '100%', maxWidth: '100vw', overflowX: 'hidden' }}
      // padding={3}
    >
      <Grid item xs={12} style={{ width: '100%' }} id="home">
        <MyrtechNavBar />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        <MyrtechLanding />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }} id="industries">
        <MyrtechIndustries />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }} id="services">
        <MyrtechIndusServ />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }} id="about">
        <MyrtechAboutUs />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }} id="contact">
        <MyrtechGetInTouch />
      </Grid>
    </Grid>
  );
};

export default MyrtechMain;
