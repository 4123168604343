// eslint-disable-next-line
import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import MyrtechMain from './components/MyrtechMain';

const App: React.FC = () => {
  return (
    <BrowserRouter>

      <Routes>
        <Route path="/" element={<MyrtechMain />}/>
        {/* <Route render={() => <Redirect to="/" />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
