import { Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
// eslint-disable-next-line
import React, { FC } from 'react';
import { textStyles } from '../CommonStyles/textStyles';

interface MyrtechContactProps {}

const MyrtechContact: FC<MyrtechContactProps> = () => {
  const { textStyle, centeredText } = textStyles();

  return (
    <Paper
      elevation={0}
      style={{ backgroundColor: 'transparent', width: '100vw' }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        padding={3}
      >
        <Grid item>
          <img alt="Logo" src={'logo.png'} style={{ width: '100px' }} />
        </Grid>
        <Grid item>
          <Typography
            className={clsx(textStyle, centeredText)}
            variant="h5"
          >
      Archon Technologies
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt="email icon"
                src="email.webp"
                style={{ width: '20px' }}
              />
              <Typography
                className={clsx(textStyle, centeredText)}
                variant="body1"
                style={{ paddingLeft: '8px' }}
              >
    info@myrmidon.technology
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            className={clsx(textStyle, centeredText)}
            variant="body1"
            component="div"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <a href="https://misunderstandinglife.substack.com/" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}>
              <img alt ="link_logo" src={'link_logo.png'} style={{ width: '13px', marginRight: '7px' }} />
              Subscribe to our Substack
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MyrtechContact;
