/* eslint-disable indent */
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
// eslint-disable-next-line
import React, { FC, useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { globalBGColorAlt, textStyles } from '../CommonStyles';
import { HashLink } from 'react-router-hash-link';
import styles from './MyrtechNavBar.module.css';

interface MyrtechNavBarProps {}

const pages = ['Home', 'Industries', 'Services', 'About', 'Contact'];
const getPageNavId = (pageName: string) => {
  return `/#${pageName.toLowerCase()}`;
};
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const MyrtechNavBar: FC<MyrtechNavBarProps> = () => {
  const { textStyle } = textStyles();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const previousPosition = React.useRef(0);
  const [isNavbarVisible, setNavBarVisible] = React.useState(true);

  // handle the scroll event
  const handleScroll = () => {
    const currentPosition = window.scrollY;
    let delta = previousPosition.current - currentPosition;
    if (previousPosition.current === 0) {
      delta = 1;
    }

    if (delta > 0) {
      // Scrolling up
      setNavBarVisible(true);
    } else {
      // Scrolling down
      setNavBarVisible(false);
    }

    previousPosition.current = currentPosition;
  };

  useEffect(() => {
    // set a listener to the scroll event
    window.addEventListener('scroll', handleScroll);
  }, []);

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pageFont = 'monospace';

  return (
    <AppBar
      className={isNavbarVisible ? styles.appear : styles.disappear}
      style={{
        backgroundColor: globalBGColorAlt
      }}
      position="fixed"
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
            <img alt="Company Logo" src="logo.png" height="50px" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="default"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages.map((page) => (
                <HashLink
                  key={page}
                  smooth
                  to={getPageNavId(page)}
                  style={{ textDecoration: 'none' }}
                >
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography
                      fontFamily={pageFont}
                      className={textStyle}
                      variant="h4"
                    >
                      {page}
                    </Typography>
                  </MenuItem>
                </HashLink>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'flex-end'
            }}
          >
            {pages.map((page) => (
              <HashLink
                key={page}
                smooth
                to={getPageNavId(page)}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  className={textStyle}
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
              </HashLink>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MyrtechNavBar;
