import { Avatar, Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
// eslint-disable-next-line
import React, { FC } from 'react';
import { globalBGColor, textStyles, textVariant } from '../CommonStyles/textStyles';

interface MyrtechAboutUsProps {}

const MyrtechAboutUs: FC<MyrtechAboutUsProps> = () => {
  const avatarSize = 200;
  const avatarPaddingLeftRight = 3;
  const { textStyle, centeredText } = textStyles();
  const textWidth = '95vw';

  return (
    <Paper elevation={0} style={{ backgroundColor: globalBGColor, width: '100vw' }}>
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        padding={3}
      >
      </Grid>
      <Grid item container
        direction="row"
        justifyContent="center"
        alignItems="center" >
        <Grid item paddingLeft={avatarPaddingLeftRight} paddingRight={avatarPaddingLeftRight}>
          <Avatar
            alt="Frankó Mátyás Krisztián"
            src="Krisztian-01.webp"
            sx={{ width: avatarSize, height: avatarSize }}
          />
        </Grid>
        <Grid item paddingLeft={avatarPaddingLeftRight} paddingRight={avatarPaddingLeftRight}>
          < Avatar
            alt="Sevecsek Balázs"
            src="Sevi-01.webp"
            sx={{ width: avatarSize, height: avatarSize }}
          />
        </Grid>
      </Grid>
      <Grid item paddingTop={6}>
        <Typography className={clsx(textStyle, centeredText)} variant="h3" textAlign="center" align="center">
            About Us
        </Typography>
      </Grid>
      <Grid
        item
        container
        textAlign='justify'
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        paddingBottom={10}
      >
        <Grid item paddingTop={2} paddingBottom={2}
          style={{ width: textWidth }}>
          <Typography className={textStyle} variant={textVariant}>
            {'During our professional careers as software engineers, we have worked with great people on fantastic projects, but we also saw a lot of challenges and hardship.'}
          </Typography>
        </Grid>
        <Grid item paddingTop={2} paddingBottom={2}
          style={{ width: textWidth }}>
          <Typography className={textStyle} variant={textVariant}>
            {'Over the years we came to know countless pain points and struggles that could have been entirely avoided with using the right approach and by following best practices. We also experienced first-hand just how useful an outsider\'s objective viewpoint can be when dealing with difficult problems.'}
          </Typography>
        </Grid>
        <Grid item paddingTop={2} paddingBottom={2}
          style={{ width: textWidth }}>
          <Typography className={textStyle} variant={textVariant}>
            {'That\'s why we are here: to help you build excellent products and services.'}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MyrtechAboutUs;
