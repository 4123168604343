import { Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
// eslint-disable-next-line
import React, { FC } from 'react';
import { globalBGColor, textStyles } from '../CommonStyles/textStyles';
import MyrtechTiles from '../Tiles';

interface MyrtechIndustriesProps {}

const MyrtechIndustries: FC<MyrtechIndustriesProps> = () => {
  const { textStyle, centeredText } = textStyles();
  return (
    <Paper elevation={0} style={{ backgroundColor: globalBGColor, width: '100vw' }}>
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        padding={3}
      >
        <Grid item paddingTop={10} paddingBottom={10}>
          <Typography className={clsx(textStyle, centeredText)} variant="h2" >
            Industries We Serve
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          // padding={1}
        >
          <MyrtechTiles
            iconLocation='telecom.webp'
            title="Telecommunications & IT"
            description='With 5G just around the corner, it is bound to change our world in unexpected ways. We can help you navigate this unprecedented increase in connectivity.'
          />
          <MyrtechTiles
            iconLocation='industry.webp'
            title="Industry 4.0"
            description='The newest technical revolution disrupts the way we produce. As IoT and industry engineers, we stand by you to tackle that challenge.'
          />
          <MyrtechTiles
            iconLocation='healthcare.webp'
            title="Healthcare"
            description='Modern medicine relies ever increasingly on cutting edge technological solutions.'
          />
          <MyrtechTiles
            iconLocation='cloud.webp'
            title="ICT Service Providers"
            description='The backbone of each and every business depends on the quality of services it consumes to give the best experience to its customers. We can help you raise your service to the highest excellence.'
          />
          <MyrtechTiles
            iconLocation='automotive.webp'
            title="Automotive Industry"
            description="We can help the automotive industry conquer tomorrow's challenges to win the race of digital transformation for autonomous driving."
          />
          <MyrtechTiles
            iconLocation='crypto.webp'
            title="Finance"
            description='The appearance of cryptocurrencies has been shaping our financial life in interesting ways. We think it’s an exciting and promising field, one that still has a lot of possibilities left unexplored.'
          />

        </Grid>
      </Grid>
    </Paper>
  );
};
export default MyrtechIndustries;
