import { Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { FC } from 'react';
import { textStyles } from './CommonStyles/textStyles';

interface MyrtechTilesProps {
    iconLocation: string,
    title: string,
    description: string,
}

const MyrtechTiles: FC<MyrtechTilesProps> = (inputProps: MyrtechTilesProps) => {
  const { iconLocation, title, description } = inputProps;
  const { textStyle, centeredText } = textStyles();
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      xs={12}
      md={6}
      lg={4}
      paddingTop={4}
      style={{ minHeight: '350px', display: 'flex' }}
    >
      <Paper elevation={0} style={{ backgroundColor: 'transparent', padding: '16px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Grid item container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ flexGrow: 0 }}
        >
          <Grid item>
            <img alt="" src={iconLocation} style={{ width: '60px', height: '60px' }}/>
          </Grid>
        </Grid>
        <Grid item style={{ flexGrow: 0, minHeight: '80px' }}>
          <Typography className={clsx(textStyle, centeredText)} textAlign="center" variant="h5" style={{ backgroundColor: 'transparent' }}>
            {title}
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Typography className={textStyle} textAlign="justify" variant="h6" padding={2} style={{ marginTop: 'auto', backgroundColor: 'transparent' }}>
            {description}
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default MyrtechTiles;
