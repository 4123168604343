import React, { FC } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import { globalBGColorAlt, textStyles } from '../CommonStyles/textStyles';
import MyrtechContact from './MyrtechContact';
interface MyrtechGetInTouchProps {}
const MyrtechGetInTouch: FC<MyrtechGetInTouchProps> = () => {
  const { textStyle, centeredText } = textStyles();

  return (
    <Paper
      elevation={0}
      style={{ backgroundColor: globalBGColorAlt, width: '100vw' }}
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        padding={3}
      ></Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="contact icon"
          src={'contact.webp'}
          style={{ width: '150px' }}
        />
      </Grid>
      <Grid item paddingTop={6}>
        <Typography
          className={clsx(textStyle, centeredText)}
          variant="h3"
          textAlign="center"
          align="center"
        >
          Get in Touch
        </Typography>
        <MyrtechContact />
      </Grid>
    </Paper>
  );
};

export default MyrtechGetInTouch;
