import { Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
// eslint-disable-next-line
import React, { FC } from 'react';
import { globalBGColorAlt, textStyles } from '../CommonStyles/textStyles';
import data from './data.json';
import MyrtechTiles from '../Tiles';

interface MyrtechIndusServProps {}

const MyrtechIndusServ: FC<MyrtechIndusServProps> = () => {
  const { textStyle, centeredText } = textStyles();

  return (
    <Paper
      elevation={0}
      style={{ backgroundColor: globalBGColorAlt, width: '100%', maxWidth: '100vw', overflowX: 'hidden' }}
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        padding={3}
        style={{ width: '100%' }}
      >
        <Grid item paddingTop={10} paddingBottom={10}>
          <Typography className={clsx(textStyle, centeredText)} variant="h2">
            {data.BigTitle}
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          style={{ width: '100%' }}
          // padding={1}
        >
          {data.boxes.map((box, index) => (
            <MyrtechTiles
              key={index}
              iconLocation={box.icon}
              title={box.title}
              description={box.description}
            />
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};
export default MyrtechIndusServ;
