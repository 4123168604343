import { Button, Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
// eslint-disable-next-line
import React, { FC } from 'react';
import { Parallax } from 'react-parallax';
import { HashLink } from 'react-router-hash-link';
import { globalBGColorAlt, textStyles } from '../CommonStyles/textStyles';
interface MyrtechLandingProps {}

const useStyles = makeStyles((theme: Theme) => ({
  transparentPaper: {
    // padding: theme.spacing(1),
    // opacity: 0.5,
    background: 'rgba(255,255,255,0.7)',
    maxWidth: '450px'
  }
}));

const MyrtechLanding: FC<MyrtechLandingProps> = () => {
  const { transparentPaper } = useStyles();
  const { textStyle, altTextStyle, button, centeredText } = textStyles();

  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item xs={12} style={{ width: '100vw' }}>
        <Parallax bgImage="bg-image-2.jpg" strength={200}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            paddingTop={20}
            paddingBottom={10}
          >
            <Grid
              item
              paddingTop={5}
              paddingBottom={5}
              xs={12}
              md={6}
              lg={4}
              paddingRight={2}
              paddingLeft={2}
            >
              <Paper elevation={3} className={transparentPaper}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  paddingTop={7}
                  paddingLeft={1}
                  paddingRight={1}
                  paddingBottom={2}
                >
                  <Grid item>
                    <Typography
                      className={clsx(textStyle, centeredText)}
                      variant="h2"
                      fontFamily="monospace"
                    >
                      Technical expertise straight from industry veterans.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                  >
                    <Grid item padding={2}>
                      <HashLink
                        key="buttonAbout"
                        smooth
                        to="/#about"
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          variant="outlined"
                          className={button}
                          style={{ backgroundColor: globalBGColorAlt }}
                        >
                          <Typography
                            className={clsx(textStyle, centeredText)}
                            variant="body1"
                            fontFamily="monospace"
                          >
                            Our Story
                          </Typography>
                        </Button>
                      </HashLink>
                    </Grid>
                    <Grid item padding={2}>
                      <HashLink
                        key="buttonContact"
                        smooth
                        to="/#contact"
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          variant="contained"
                          className={button}
                          style={{ backgroundColor: 'black' }}
                        >
                          <Typography
                            className={clsx(altTextStyle, centeredText)}
                            variant="body1"
                            fontFamily="monospace"
                          >
                            Contact Us
                          </Typography>
                        </Button>
                      </HashLink>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Parallax>
      </Grid>
    </Grid>
  );
};

export default MyrtechLanding;
